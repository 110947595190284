import moment from 'moment/moment';
import logoPng from '../assets/images/logos/logo-png.png';
import { BUSINESS_TYPE_LIST } from '../constants';
export function strToUpper(mySentence) {
  const words = mySentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i] && words[i][0]) { words[i] = words[i][0].toUpperCase() + words[i].substr(1); }
  }
  return words.length > 0 ? words.join(' ') : mySentence;
}
export function handleBrokenImage(ev) {
  return ev.target.src = logoPng;
}
export function toFourDigit(number) {
  if (number < 10) {
    return `000${number}`;
  } else if (number < 100) {
    return `00${number}`;
  } else if (number < 1000) {
    return `0${number}`;
  }
  return number;
}
export function disableEnterKey(e) {
  if (e.key === 'Enter') { e.preventDefault(); }
}
export function makeUrl(url = '') {
  if (url) {
    if (url.search("http://") == -1 && url.search("https://") == -1) {
      return `https://${url}`;
    }
    return url;
  }
}
export function toUtcFormat(date) {
  return moment(date).format();
}
export function isStoreFront() {
  const portals = ['app', 'dev2', 'qa2','stage'];
  let isFrontStore = true;
  
  portals.map(p => {
    if (window.location.hostname.search(`${p}.shopdotapp`) >= 0 || window.location.hostname == "localhost") {
      isFrontStore = false;
    }
  });
  return isFrontStore;
}
export function configueCss(details) {
  const favicon = document.getElementById("favicon");
  if (favicon) {


    if (details?.store_favicon?.includes('image-upload-favicon')) {
      favicon.setAttribute("href", "");
    }
    else {
      favicon.setAttribute("href", details?.store_favicon);

    }
  }

  const heroText = document.getElementById("hero-text");
  if (heroText) {
    heroText.style.textAlign = details?.hero_text_alignment;
    heroText.style.color = details?.hero_text_font_color;
  }
  const header = document.getElementsByTagName('header')[0];
  if (header) {
    header.style.backgroundColor = details?.header_background_color;
  }
  const headerFont = document.getElementsByClassName('header-font');
  if (headerFont && headerFont?.length > 0) {
    for (let i = 0; i < headerFont.length; i++) {
      headerFont[i].style.color = details?.header_font_color;
    }
  }
  const footer = document.getElementsByTagName('footer')[0];
  if (footer) {
    footer.style.backgroundColor = details?.footer_background_color;
  }
  const footerFont = document.getElementsByClassName('footer-font');
  if (footerFont && footerFont?.length > 0) {
    for (let i = 0; i < footerFont.length; i++) {
      footerFont[i].style.color = details?.footer_font_color;
    }
  }
  const buttons = document.getElementsByClassName('dynamic-btn');
  if (buttons && buttons?.length > 0) {
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].style.backgroundColor = details?.button_background_color;
      buttons[i].style.color = details?.button_font_color;
    }
  }
  const homeIcon = document.getElementsByClassName('home');
  if (homeIcon && homeIcon?.length > 0) {
    for (let i = 0; i < homeIcon.length; i++) {
      homeIcon[i].style.backgroundColor = details?.icon_color;
    }
  }
  const cartIcon = document.getElementsByClassName('cart-inside');
  if (cartIcon && cartIcon?.length > 0) {
    for (let i = 0; i < cartIcon.length; i++) {
      cartIcon[i].style.backgroundColor = details?.icon_color;
    }
  }

  const body = document.getElementsByClassName('ha-mart-page');
  if (body && body?.length > 0) {
    for (let i = 0; i < body.length; i++) {
      body[i].style.backgroundColor = details?.background_color;
    }
  }
}
export function numberToText(num) {
  const nums = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
  ];
  if (num < 10) {
    return nums[num];
  }
  return num;
}
export function getVariantTitle(variantOptions) {
  if (variantOptions?.length > 0) {
    const v = [...variantOptions];
    return v?.sort((a, b) => a?.sequence - b?.sequence);
  }
  return [];
}

export const calculateStock = (totalInventory, brand_retailer_preference, onlyNumber = false) => {
  let stock = 0;
  const { inventory_buffer } = brand_retailer_preference || {};
  if (totalInventory) {
    const totalStockQuantityInt = parseInt(totalInventory);
    stock = (totalStockQuantityInt * inventory_buffer) / 100;
  }
  if (onlyNumber)
    return Math.floor(stock);
  return stock > 50 ? '>50' : stock <= 0 ? 0 : Math.floor(stock);
};

export const getProductStock = (product_variants, brand_retailer_preference) => {
  let stockCount = 0;
  if (product_variants && product_variants?.length > 0) {
    for (const variant of product_variants) {
      const stockCountVariant = calculateStock(variant?.inventory_quantity, brand_retailer_preference);

      if (stockCountVariant === ">50") {
        return '>50';
      }
      stockCount += stockCountVariant;
    }
  }
  return stockCount > 50 ? '>50' : stockCount;
};
export const truncateText = (str = "") => {
  if (!str?.length) return "";
  return str?.length > 30 ? str?.trim().substring(0, 30) + "..." : str?.trim();
}


export const isCurrentTimeWithinDateRange = (startDate, endDate) => {

  // Set the time for the start_date to the start of the day
  let startDateTime = new Date(startDate);
  startDateTime.setHours(0, 0, 0, 0);

   // Set the time for the end_date to the end of the day if it exists, otherwise set it to a far future date

   let endDateTime = endDate ? new Date(endDate) : new Date(86400000000000); // Using max date if endDate is not provided

  endDateTime.setHours(23, 59, 59, 999);

  // Get the current date and time
  let currentDateTime = new Date();

  // Check if the current time is within the range
  return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
}


export const getRetailerBusinessType = (business_type) => {
  const get_business_type = BUSINESS_TYPE_LIST.find((item) => item.value == business_type)
  return get_business_type ? get_business_type.label : ''
}

export const getStoreUrl = (sub_domain) => {

  let storeFrontDomain = '.myshopdotdev';
  if (window.location.href.search("app.shopdotapp.com") > -1) {
    storeFrontDomain = '.myshopdot';

  } else if (window.location.href.search('qa2.shopdotapp.com') > -1) {
    storeFrontDomain = '.myshopdotqa';
  }
  return `${sub_domain}${storeFrontDomain}.com`
}


export const capitalizeFirstLetter = (string) => {
  if(!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const handleMaxPercentage = (value) => {
  value = value.toString();

  value = value.replace(/[^0-9.]/g, "");

  const decimalIndex = value.indexOf(".");
  if (decimalIndex !== -1) {
    value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(/\./g, "");
  }

  if (value.includes(".")) {
    const [integerPart, decimalPart] = value.split(".");
    value = `${integerPart}.${decimalPart.slice(0, 2)}`;
  }

  const numericValue = parseFloat(value);
  if (!isNaN(numericValue)) {
    if (numericValue > 100) {
      value = "100";
    } else if (numericValue < 0) {
      value = "0";
    }
  }
  else
  {
    value = "0"
  }

  return value;
};